import React from "react";

export default function AppFan() {
    return (
        <div className="fan">
            <img className="fan-image" src="/img/fan/staffid.png" alt="Spara appið" />
            <img className="fan-image" src="/img/fan/leb.png" alt="Spara appið" />
            <img className="fan-image" src="/img/fan/tviund1.png" alt="Spara appið" />
            <img className="fan-image" src="/img/fan/2f1.png" alt="Spara appið" />
            <img className="fan-image" src="/img/fan/landspitali.png" alt="Spara appið" />
        </div>
    );
}
