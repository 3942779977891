import React from "react";

import Page from "../components/layout/page";

import { withAuth } from "../utils/auth";
import { withRouter } from "next/router";
import ReactGA_UA from "react-ga";
import ReactGA_4 from "react-ga4";
import { AppBanner } from "../components/AppBanner";
import { Title } from "../components/ui";
import AppFan from "../components/AppFan";

function TorgidPage() {
    React.useEffect(() => {
        ReactGA_UA.pageview("/");
        ReactGA_4.send("pageview");
    });

    return (
        <Page>
            <section className="section-container about-us-section">
                <AppFan />
                <div style={{ alignSelf: "center" }}>
                    <Title size={2} style={{ marginBottom: 20 }}>
                        Allir græða
                    </Title>

                    <p>
                        Appið Spara sameinar þína ólíku vildarhópa og tilboð þeirra á einn stað, í
                        appinu. Þar sérðu alla hópana sem þú ert í, félagaskírteinin, virkjað
                        tilboðin og sparað pening.
                    </p>
                    <p>
                        Appið er í stanslausri þróun og ef þú hefur hugmynd um hvernig sé hægt að
                        bæta appið, sendu okkur línu á{" "}
                        <a href="mailto:spara@spara.is">spara@spara.is</a>.
                    </p>
                    <AppBanner>
                        Sæktu <b>Spara</b> appið frítt hér!
                    </AppBanner>
                </div>
            </section>
            <section className="section-two-container about-us-section">
                <img
                    className="phone-image"
                    style={{ alignSelf: "center" }}
                    src="/img/frumherji.png"
                />
                <div style={{ alignSelf: "center" }}>
                    <Title size={2} style={{ marginBottom: 20 }}>
                        Afslættir af allskonar
                    </Title>
                    <p>Spara er ekki bara 2 fyrir 1 app!</p>
                    <p>
                        Frítt stöff, krónuafslættir, prósentuafslættir og að sjálfsögðu 2 fyrir 1
                        líka. Fyrirtækin eru allskonar með allskonar afslætti og tilboð!
                    </p>
                    <p>
                        Við þjónustum í dag hópa á borð við VR, Landssamband eldri borgara, fjölmörg
                        starfsmannafélög og nemendafélög.
                    </p>
                </div>
                <img className="phone-image" style={{ alignSelf: "center" }} src="/img/stod2.png" />
            </section>

            <section className="section-three-container about-us-section">
                <img style={{ alignSelf: "center" }} src="/img/home.png" />
                <div style={{ alignSelf: "center" }}>
                    <Title size={2} style={{ marginBottom: 20 }}>
                        Sparaðu þér bras
                    </Title>
                    <p style={{ fontSize: 22, fontStyle: "italic" }}>
                        „Starfsmannafélag Landspítala innleiddi Spara{" "}
                        <span style={{ color: "#DE2C48" }}>(áður Torgið)</span> afsláttarappið fyrir
                        félagsmenn sína árið 2022. Nýja fyrirkomulagið hefur vakið mikla lukku og
                        einfaldað til muna aðgengi að afsláttum fyrir félagsmenn. Við mælum
                        eindregið með lausninni sem Spara hefur upp á að bjóða auk þess sem
                        starfsfólkið þar er til fyrirmyndar, ávallt reiðubúið til aðstoðar ef
                        eitthvað er“
                    </p>
                    <p>- Starfsmannafélag Landspítala</p>
                </div>
            </section>

            <section className="about-us-section">
                <Title size={2} style={{ textAlign: "center", marginBottom: 50 }}>
                    Spara samfélagið
                </Title>

                <div className="partner-logo-wrapper">
                    <img className="partner-logo" src="/img/samfelag/vr.jpeg"></img>
                    <img className="partner-logo" src="/img/samfelag/staffid.png"></img>
                    <img className="partner-logo" src="/img/samfelag/leb.png"></img>
                    <img className="partner-logo" src="/img/samfelag/landspitali.png"></img>
                    <img className="partner-logo" src="/img/samfelag/islandshotel.png"></img>
                    <img className="partner-logo" src="/img/samfelag/vodafone.png"></img>
                    <img className="partner-logo" src="/img/samfelag/tviund.png"></img>
                    <img className="partner-logo" src="/img/samfelag/styna.png"></img>
                    {/* <img className="partner-logo" src="/img/samfelag/samskip.png"></img> */}
                </div>
            </section>
        </Page>
    );
}

export default withRouter(withAuth(TorgidPage));
