import React, { Component } from "react";

export function withAuth(AuthComponent) {
    return class Authenticated extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isAuth: false,
                isLoading: true,
                coupon: "",
            };
        }

        componentDidMount() {
            this.setState({
                isAuth: true,
            });
        }

        render() {
            if (this.state.isAuth == false) return null;
            return <AuthComponent {...this.props} coupon={this.state.coupon} />;
        }
    };
}
