import React from "react";
export function AppBanner({ children }) {
    return (
        <a style={{ textDecoration: "none" }} href="https://onelink.to/dpbweh">
            <div
                className="d-flex justify-content-center rounded"
                style={{
                    backgroundColor: "#FBEAEC",
                    borderColor: "#DE2C48",
                    borderWidth: 1,
                    borderStyle: "solid",
                    justifyContent: "center",
                    verticalAlign: "center",
                    alignSelf: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ margin: 10, height: 50 }}>
                    <img
                        src={"/app-icon.png"}
                        style={{ height: 50, width: 50, borderRadius: 12 }}
                        alt="Spara icon"
                    />
                </div>
                <p
                    style={{
                        marginRight: 10,
                        justifyContent: "center",
                        verticalAlign: "center",
                        alignSelf: "center",
                        color: "#DE2C48",
                        marginBottom: 10,
                        marginTop: 10,
                    }}
                >
                    {!children ? (
                        <>
                            Til þess að virkja þetta tilboð, þarftu að sækja appið <b>Spara</b>.
                            Smelltu hér.
                        </>
                    ) : (
                        children
                    )}
                </p>
            </div>
        </a>
    );
}
